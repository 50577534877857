import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { StyledDatePicker, DatePickerContainer, CalenderButton } from './styles';
import { DatePickerChildren, DatePickerHeader, DatePickerInput } from './components';
import { useThemeContext } from '../theme/CustomThemeProvider';

interface DatePickerProps {
  fullWidth: boolean;
  date: Date | null;
  setDate: (date: Date | [Date | null, Date | null] | null) => void;
  onChange?: (date: Date) => void;
  disabled: boolean;
  minDate: Date;
  maxDate: Date;
  placeholder?: string;
}

const CalendarImg = () => {
  const { theme } = useThemeContext();
  return <img src={theme.icons.calendar} alt="calendar icon" />;
};

export const CalendarComponent = CalendarImg;

const DatePicker = ({
  fullWidth,
  setDate,
  disabled,
  date,
  minDate,
  maxDate,
  placeholder,
  onChange,
}: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(date);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleDateChange = (date: Date) => {
    if (date) {
      setSelectedDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    onChange && onChange(date);
  };

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    ['click', 'focusin'].forEach((e) => document.addEventListener(e, handleOutsideClick));
    return () => {
      ['click', 'focusin'].forEach((e) => document.removeEventListener(e, handleOutsideClick));
    };
  }, []);
  return (
    <DatePickerContainer
      ref={containerRef}
      onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
      className="date-picker-container"
    >
      <CalenderButton onClick={() => setIsOpen(!isOpen)} disabled={disabled} data-testid="calendarButton">
        <CalendarComponent />
      </CalenderButton>
      <StyledDatePicker
        name="Date"
        selected={selectedDate}
        onChange={(date: Date) => handleDateChange(date)}
        minDate={minDate}
        maxDate={maxDate}
        fullWidth={fullWidth}
        disabled={disabled}
        open={isOpen}
        onFocus={() => setIsOpen(true)}
        onCalendarClose={() => setSelectedDate(date)}
        placeholderText={placeholder}
        formatWeekDay={(day) => day.slice(0, 1)}
        shouldCloseOnSelect={false}
        renderCustomHeader={({ changeYear, changeMonth }) => (
          <DatePickerHeader
            changeMonth={changeMonth}
            changeYear={changeYear}
            selectedDate={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        customInput={<DatePickerInput />}
      >
        <DatePickerChildren
          disabled={!selectedDate}
          setDate={() => {
            setIsOpen(false);
            setDate(selectedDate);
          }}
          cancelPicker={() => {
            setIsOpen(false);
            setSelectedDate(date);
          }}
        />
      </StyledDatePicker>
    </DatePickerContainer>
  );
};

export default DatePicker;
