import isEqual from 'lodash/isEqual';
import { PAGES } from '../constants';
import { MAKE_RATE_CALL } from '../actions/quote';
import handleRateCallOne from '../actions/handleRateCallOne';
import handleRateCallTwo from '../actions/handleRateCallTwo';
import {
  ADD_ADDITIONAL_INSURED,
  REMOVE_ADDITIONAL_INSURED,
  SET_APPLICATION_TYPES,
  SET_BPP_DEDUCTIBLE,
  SET_EQUIPMENT_BREAKDOWN_COVERAGE,
  SET_GL_LIMIT,
  SET_HIRED_AND_NON_OWNED_AUTO,
  SET_LIQUOR_LIABILITY_COVERAGE,
  SET_RESTAURANT_COVERAGE,
  UPDATE_APPLICATION_FROM_SERVER,
  UPDATE_LOCATION,
  UPDATE_PROFESSIONAL_LIABILITY,
  SET_LOSS_CONTROL,
  SET_LIMIT_DAMAGE_PREM_RENTED,
  SET_CONTRACTOR_TOOLS_COVERAGE,
  SET_CYBER_COVERAGE,
  SET_WORKPLACE_VIOLENCE_COVERAGE,
  SET_RETROACTIVE_DATE,
  SET_IS_RETROACTIVE_DATE_CHECKED,
} from '../actions/application';
import isApplicationBindable from '../helpers/isApplicationBindable';
import addApplicationAggregate from '../helpers/addApplicationAggregate';
import { isAddOnsComplete } from '../selectors/progress';
import { selectQuotes } from '../selectors/quote';
import { selectPartnerAgencyExternalId, selectAgentProducerExternalId } from '../selectors';
import { AnyAction, Middleware } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { isApplicationQuotable } from '../helpers/isApplicationQuotable';
import { selectIsAgentMDA } from '../selectors/agent';

const rateCalls: Middleware<{}, ReduxState> = (store) => (next) => (action) => {
  const state = store.getState();
  const { application, location } = state;
  const { type } = action;
  const isSummaryPage = location.type === PAGES.SUMMARY;
  const isAddOnPage = location.type === PAGES.ADD_ONS;
  const hasQuotes = Boolean(selectQuotes(state).length);
  const isUserMDA = selectIsAgentMDA(store.getState());
  /**
   * Update the redux store and pull the updated application state by calling next(action)
   * so we can make the rate call with the updated application
   */
  const result = next(action);
  const { application: updated_application } = store.getState();

  const makeRateCall = ({ ignoreApplicationChanges } = { ignoreApplicationChanges: false }) => {
    const applicationHasUpdatedCheck = ignoreApplicationChanges || !isEqual(application, updated_application);

    const producerExternalId = selectAgentProducerExternalId(store.getState());
    const agencyExternalId = selectPartnerAgencyExternalId(store.getState());
    const addProducerOrAgencyExternalId = isUserMDA ? { producerExternalId } : { agencyExternalId };

    if (isApplicationQuotable(updated_application) && applicationHasUpdatedCheck) {
      const application_with_agency_external_id = { ...updated_application, ...addProducerOrAgencyExternalId };
      const updated_application_with_aggregates = addApplicationAggregate(application_with_agency_external_id);

      // when we pass the limitDamagePremRented value to the api, we want to reduce it by 50k since
      // the limitDamagePremRented add on already comes with a base limit of 50k
      const shouldAddLimitDamagePremRented =
        updated_application_with_aggregates.limitDamagePremRented &&
        updated_application_with_aggregates.limitDamagePremRented >= 50_000
          ? { limitDamagePremRented: updated_application_with_aggregates.limitDamagePremRented - 50_000 }
          : {};

      const applicationPayload = {
        ...updated_application_with_aggregates,
        ...shouldAddLimitDamagePremRented,
      };
      (store.dispatch as ThunkDispatch<ReduxState, void, AnyAction>)(
        isApplicationBindable(applicationPayload)
          ? handleRateCallTwo(applicationPayload)
          : handleRateCallOne(applicationPayload)
      );
    }
  };

  switch (type) {
    case UPDATE_APPLICATION_FROM_SERVER:
    case REMOVE_ADDITIONAL_INSURED:
    case ADD_ADDITIONAL_INSURED:
    case SET_LOSS_CONTROL:
    case MAKE_RATE_CALL:
      makeRateCall();
      break;
    case SET_HIRED_AND_NON_OWNED_AUTO:
    case SET_LIQUOR_LIABILITY_COVERAGE:
    case SET_RESTAURANT_COVERAGE:
    case SET_EQUIPMENT_BREAKDOWN_COVERAGE:
    case SET_LIMIT_DAMAGE_PREM_RENTED:
    case SET_CONTRACTOR_TOOLS_COVERAGE:
    case SET_CYBER_COVERAGE:
    case SET_WORKPLACE_VIOLENCE_COVERAGE:
      if (isAddOnsComplete(store.getState()) && (isSummaryPage || isAddOnPage)) {
        makeRateCall();
      }
      break;
    case PAGES.GENERAL_LIABILITY:
    case PAGES.PROFESSIONAL_LIABILITY:
    case SET_RETROACTIVE_DATE:
    case SET_IS_RETROACTIVE_DATE_CHECKED:
      makeRateCall({ ignoreApplicationChanges: true });
      break;
    case SET_BPP_DEDUCTIBLE:
    case UPDATE_LOCATION:
    case UPDATE_PROFESSIONAL_LIABILITY:
    case SET_GL_LIMIT:
      if (!isSummaryPage) {
        makeRateCall();
      }
      break;
    case PAGES.SUMMARY:
      if (!hasQuotes) {
        makeRateCall({ ignoreApplicationChanges: true });
      }
      break;
    case SET_APPLICATION_TYPES:
      if (isSummaryPage) {
        makeRateCall();
      }
      break;
    default:
      break;
  }

  return result;
};

export default rateCalls;
