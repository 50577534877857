import { addYears } from 'date-fns';
import { createSelector } from 'reselect';
import { isYearsOfPriorActsValid } from '../helpers/isYearsOfPriorActsValid';
import isRetroactiveDateValid from '../helpers/isRetroactiveDateValid';
import { PAGES } from '../constants';

export const selectApplication = (state: ReduxState) => state.application;
export const selectApplicationId = (state: ReduxState) => selectApplication(state).applicationId;
export const selectMetadata = (state: ReduxState) => selectApplication(state).metadata;
export const selectIndustryState = (state: ReduxState) => selectApplication(state).industryId;
export const selectAkHash = (state: ReduxState) => selectApplication(state).akHash;
export const selectNumEmployees = (state: ReduxState) => selectApplication(state).numEmployees;
export const selectPreviousLosses = (state: ReduxState) => selectApplication(state).previousLosses;
export const selectPolicyTypes = (state: ReduxState) => selectApplication(state).applicationTypes || [];
export const selectAnnualPayroll = (state: ReduxState) => selectApplication(state).annualPayroll;
export const selectGrossAnnualSales = (state: ReduxState) => selectApplication(state).grossAnnualSales;
export const selectPlLimit = (state: ReduxState) => selectProfessionalLiability(state)?.occurrenceLimit;
export const selectPlDeductible = (state: ReduxState) => selectProfessionalLiability(state)?.deductibleAmount;
export const selectLossControl = (state: ReduxState) => selectApplication(state).lossControl;

export const selectFirstLocation = (state: ReduxState) => selectLocations(state)[0];
export const selectLocationBuildingType = (state: ReduxState) => selectFirstLocation(state)?.locationType;
export const selectFailFastZip = (state: ReduxState) => selectFirstLocation(state)?.zip || '';
export const selectLocationState = (state: ReduxState) => selectFirstLocation(state)?.state;
export const selectLocationStreet = (state: ReduxState) => selectFirstLocation(state)?.street;
export const selectLocationCity = (state: ReduxState) => selectFirstLocation(state)?.city;
export const selectLocationZip = (state: ReduxState) => selectFirstLocation(state)?.zip;
export const selectLocationBPPLimit = (state: ReduxState) => selectFirstLocation(state)?.bppLimit;
export const selectBPPDeductible = (state: ReduxState) => selectApplication(state).bppDeductible;
export const selectLocationBuildingLimit = (state: ReduxState) => selectFirstLocation(state)?.buildingLimit;
export const selectHasSprinklers = (state: ReduxState) => selectFirstLocation(state)?.hasSprinklers;
export const selectGLLimit = (state: ReduxState) => selectApplication(state).glLimit;
export const selectTwoFourLimitsAvailable = (state: ReduxState) => selectApplication(state).twoFourLimitsAvailable;
export const selectGLDeductible = (state: ReduxState) => selectApplication(state).propertyDamageLiabilityDeductible;
export const selectGLAggregateLimit = (state: ReduxState) => selectApplication(state).glAggregateLimit;

export const selectLegalBusinessName = (state: ReduxState) => selectApplication(state).legalBusinessName;
export const selectDBA = (state: ReduxState) => selectApplication(state).dba;
export const selectBusinessStartDate = (state: ReduxState) => selectApplication(state)?.businessStartDate;
export const selectBusinessAgeInMonths = (state: ReduxState) => selectApplication(state).businessAgeInMonths;

export const selectMailingAddressCity = (state: ReduxState) => selectApplication(state).mailingAddressCity || '';
export const selectMailingAddressStreet = (state: ReduxState) => selectApplication(state).mailingAddressStreet || '';
export const selectMailingAddressState = (state: ReduxState) => selectApplication(state).mailingAddressState || '';
export const selectMailingAddressZip = (state: ReduxState) => selectApplication(state).mailingAddressZip || '';

export const selectMailingAddressInfo = createSelector(
  selectMailingAddressStreet,
  selectMailingAddressState,
  selectMailingAddressZip,
  selectMailingAddressCity,
  (street, state, zip, city) => ({
    street,
    state,
    zip,
    city,
  })
);

export const selectLocations = (state: ReduxState) => selectApplication(state).locations || [];

export const selectProfessionalLiability = (state: ReduxState) => selectApplication(state).professionalLiability || {};
export const selectCertificationsRequired = (state: ReduxState) =>
  selectProfessionalLiability(state)?.certificationsRequired;
export const selectCertificationsMaintained = (state: ReduxState) =>
  selectProfessionalLiability(state)?.certificationsMaintained;
export const selectYearsOfPriorActs = (state: ReduxState) => selectProfessionalLiability(state)?.yearsOfPriorActs;
export const selectYearsOfProfessionalExperience = (state: ReduxState) =>
  selectProfessionalLiability(state)?.yearsOfProfessionalExperience;
export const selectRetroactiveDate = (state: ReduxState) =>
  selectApplication(state)?.professionalLiability?.retroactiveDate;
export const selectIsRetroactiveDateChecked = (state: ReduxState) => state.pl.isRetroActiveDateChecked;
export const selectShowPlv2 = (state: ReduxState) => state.pl.showPlv2;
export const selectContactFirstName = (state: ReduxState) => selectApplication(state).contactFirstName;
export const selectContactLastName = (state: ReduxState) => selectApplication(state).contactLastName;
export const selectContactEmail = (state: ReduxState) => selectApplication(state).contactEmail;
export const selectContactPhone = (state: ReduxState) => selectApplication(state).contactPhone;
export const selectAdditionalInsureds = (state: ReduxState) => selectEndorsements(state)?.additionalInsureds || [];

export const selectContactEmailIsValid = (state: ReduxState) => selectApplication(state).contactEmailIsValid;

export const selectIsYearsOfPriorActsValid = (state: ReduxState) => {
  const yearsOfPriorActs = selectYearsOfPriorActs(state);
  const businessAgeInMonths = selectBusinessAgeInMonths(state);

  if (typeof yearsOfPriorActs == 'number' && typeof businessAgeInMonths == 'number') {
    return isYearsOfPriorActsValid(yearsOfPriorActs, businessAgeInMonths);
  }

  return false;
};

export const selectIsRetroactiveDateValid = (state: ReduxState) => {
  const retroactiveDate = selectRetroactiveDate(state);
  const businessAgeInMonths = selectBusinessAgeInMonths(state);
  const businessStartDate = selectBusinessStartDate(state);
  const isRetroActiveDateChecked = selectIsRetroactiveDateChecked(state);
  const policyStartDate = selectPolicyStartDate(state);
  const page = state.location.type;
  const isSummaryPage = page === PAGES.SUMMARY;

  // Skip validation if the user selects "No" for retroactive date
  if (!isRetroActiveDateChecked) return { isValid: true };
  // Skip validation if the user is on the summary page
  if (isSummaryPage) return { isValid: true };

  if (businessAgeInMonths && businessStartDate && isRetroActiveDateChecked) {
    return isRetroactiveDateValid({
      businessAgeInMonths,
      businessStartDate,
      retroactiveDate,
      isRetroActiveDateChecked,
      policyStartDate,
    });
  }

  return { isValid: false };
};

const selectPolicyStartDateRaw = (state: ReduxState) => selectApplication(state).policyStartDate;
export const selectPolicyStartDate = (state: ReduxState) => {
  const startDate = selectPolicyStartDateRaw(state);

  const editedStartDate = new Date(startDate ?? Date.now());
  return new Date(editedStartDate.getFullYear(), editedStartDate.getMonth(), editedStartDate.getDate());
};

const selectPolicyEndDateRaw = (state: ReduxState) => selectApplication(state).policyEndDate;
export const selectPolicyEndDate = (state: ReduxState) => {
  const endDate = selectPolicyEndDateRaw(state);
  return endDate ? new Date(endDate) : addYears(new Date(), 1);
};

// Endorsements
export const selectEndorsements = (state: ReduxState) => selectApplication(state).endorsements;
export const selectEndorsementPackage = (state: ReduxState) => selectEndorsements(state)?.endorsementPackage;
export const selectHiredAutoAndNonOwnedAuto = (state: ReduxState) =>
  selectEndorsements(state)?.hiredAutoAndNonOwnedAuto || {};
const selectHiredAutoAndOwnedAuto = (state: ReduxState) => selectEndorsements(state)?.hiredAutoAndNonOwnedAuto;
export const selectHiredCoverage = (state: ReduxState) => selectHiredAutoAndOwnedAuto(state)?.hiredCoverage;
export const selectNonOwnedCoverage = (state: ReduxState) => selectHiredAutoAndOwnedAuto(state)?.nonOwnedCoverage;
export const selectLiquorLiability = (state: ReduxState) => selectEndorsements(state)?.liquorLiabilityEndorsement;
export const selectContractorToolsLimit = (state: ReduxState) =>
  selectEndorsements(state)?.contractorsInstallationAndTools?.selectedContractorsToolsLimit;
export const selectCyberCoverage = (state: ReduxState) => selectEndorsements(state)?.cyberCoverage;
export const selectWorkplaceViolenceCoverage = (state: ReduxState) =>
  selectEndorsements(state)?.workplaceViolenceCoverage;

// Blanket AI
export const selectHasBlanketAIPrimaryAndNonContributoryChecked = (state: ReduxState) =>
  selectEndorsements(state)?.additionalInsuredsPrimaryAndNonContributory;
export const selectHasBlanketAIWaiverOfTransferChecked = (state: ReduxState) =>
  selectEndorsements(state)?.additionalInsuredsWaiverOfTransfer;
export const selectHasBlanketAIConstructionContractRequirementEndorsementChecked = (state: ReduxState) =>
  Boolean(selectEndorsements(state)?.additionalInsuredsConstructionContractRequirementEndorsement?.formText);
export const selectHasBlanketAIManagersOrLessorsPremisesEndorsementChecked = (state: ReduxState) =>
  Boolean(selectEndorsements(state)?.additionalInsuredsManagersOrLessorsPremisesEndorsement?.formText);
export const selectHasBlanketAILessorOfLeasedEquipmentEndorsementChecked = (state: ReduxState) =>
  Boolean(selectEndorsements(state)?.additionalInsuredsLessorOfLeasedEquipmentEndorsement?.formText);

export const selectAreAnyBlanketAIItemsChecked = createSelector(
  selectHasBlanketAIPrimaryAndNonContributoryChecked,
  selectHasBlanketAIWaiverOfTransferChecked,
  selectHasBlanketAIConstructionContractRequirementEndorsementChecked,
  selectHasBlanketAIManagersOrLessorsPremisesEndorsementChecked,
  selectHasBlanketAILessorOfLeasedEquipmentEndorsementChecked,
  (
    primaryAndNonContributory,
    waiverOfTransfer,
    constructionContractRequirement,
    managersOrLessorsPremises,
    lessorOfLeasedEquipment
  ) =>
    primaryAndNonContributory ||
    waiverOfTransfer ||
    constructionContractRequirement ||
    managersOrLessorsPremises ||
    lessorOfLeasedEquipment
);

// User Has
const userHas = (state: ReduxState) => state.userHas || {};
export const selectUserHasSelectedAdditionalInsureds = (state: ReduxState) =>
  userHas(state)?.selectedHasAdditionalInsureds;
export const selectUserHasCompletedCheckoutForm = (state: ReduxState) => userHas(state)?.completedCheckoutForm;
export const selectUserHasSubmittedCheckoutForm = (state: ReduxState) => userHas(state)?.submittedCheckoutForm;
export const selectUserHasInvalidatedAddOns = (state: ReduxState) => userHas(state)?.invalidatedAddOns;
const selectUserHasViewed = (state: ReduxState) => userHas(state).viewed || {};
export const selectUserHasViewedGLPage = (state: ReduxState) => selectUserHasViewed(state).gl_page;
export const selectUserHasViewedAdditionalInsuredsPage = (state: ReduxState) =>
  selectUserHasViewed(state).additional_insureds_page;
export const selectUserHasViewedAddOnsPage = (state: ReduxState) => selectUserHasViewed(state).add_ons_page;
export const selectUserHasViewedSummaryPage = (state: ReduxState) => selectUserHasViewed(state).summary_page;
export const selectLimitDamagePremRented = (state: ReduxState) => selectApplication(state).limitDamagePremRented;
export const selectUserHasExistingApplication = (state: ReduxState) => userHas(state)?.existingApplication;
