import React, { useEffect, useState } from 'react';
import { LabelLarge, FormSection, SectionTitle, NumberInput, Paragraph, LabelWithToolTipCont } from '../../elements';
import styled from 'styled-components';
import ToolTipIcon from './ToolTipIcon';
import MultiRow from './MultiRow';
import YesNoQuestion from './YesNoQuestion';
import { setShowPLV2, updateProfessionalLiability } from '../../actions/application';
import handleFormSubmit from '../../actions/handleFormSubmit';
import {
  selectPlDeductible,
  selectPlLimit,
  selectYearsOfPriorActs,
  selectCertificationsRequired,
  selectCertificationsMaintained,
  selectYearsOfProfessionalExperience,
  selectGrossAnnualSales,
  selectIsYearsOfPriorActsValid,
  selectAkHash,
} from '../../selectors/application';
import { getCurrentPage } from '../../selectors/routing';
import SectionDivider from './SectionDivider';
import {
  PL_LIMIT_OCC_AGG,
  POLICY_OPTIONS,
  TOOL_TIP_CONTENT_KEY,
  VALUE_OPTIONS,
  POLICY_ARIA_LABELS,
  PAGES,
} from '../../constants';
import GhostButton from './GhostButton';
import SubmitButton from './SubmitButton';
import { isPageComplete } from '../../selectors/pageComplete';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { isPLComplete } from '../../selectors/progress';
import toolTipContent from '../../helpers/toolTipContent';
import { NumberFormatValues } from 'react-number-format';
import { ReactComponent as AlertIcon } from '../../icons/alert.svg';
import { isRealEstateClass } from '../../helpers/isRealEstateClass';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';
import RetroactiveDate from './RetroactiveDate';

interface ProfessionalLiabilityProps {
  certificationsRequired?: boolean;
  certificationsMaintained?: boolean;
  yearsOfPriorActs?: number;
  yearsOfProfessionalExperience?: number;
  occurrenceLimit?: number;
  deductibleAmount?: number;
  isSummaryPage?: boolean;
  grossAnnualSales?: number;
  onBundleSubmit?: () => void;
  handleFormSubmit: () => void;
  updateProfessionalLiability: (value: any) => void;
  isPageComplete: boolean;
  isPriorActsValid: boolean;
  akHash?: string;
  setShowPLV2?: (value: boolean) => void;
}

const ProfessionalLiability = ({
  certificationsRequired,
  certificationsMaintained,
  yearsOfPriorActs,
  yearsOfProfessionalExperience,
  occurrenceLimit,
  deductibleAmount,
  isSummaryPage,
  grossAnnualSales,
  onBundleSubmit,
  handleFormSubmit,
  updateProfessionalLiability,
  isPageComplete,
  isPriorActsValid,
  akHash,
  setShowPLV2,
}: ProfessionalLiabilityProps) => {
  const { showPlv2 } = useFeatureFlags();

  const updateSection = (field: string, value?: number | boolean | string) => {
    updateProfessionalLiability({
      grossRevenue: grossAnnualSales || 0,
      [field]: value,
      ...(field === 'occurrenceLimit' ? { aggregateLimit: PL_LIMIT_OCC_AGG[Number(value)] } : {}),
    });
  };

  const adjustYearsExp = debounce((years: NumberFormatValues) => {
    if (years.floatValue) {
      updateSection('yearsOfProfessionalExperience', years.floatValue);
    }
  }, 500);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onBundleSubmit ? onBundleSubmit() : handleFormSubmit();
  };

  const [disabledDeductibleOptions, setDisabledDeductibleOptions] = useState<number[]>([]);

  const adjustDeductibleOptions = (limit: number) => {
    const disabledOptions = VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE].filter((deductible: number) => {
      return limit < deductible;
    });
    setDisabledDeductibleOptions(disabledOptions);
    if (deductibleAmount && disabledOptions.includes(deductibleAmount)) {
      const deductible = [...VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE]].reverse().filter((option: number) => {
        return !disabledOptions.includes(option);
      })[0];
      updateSection('deductibleAmount', deductible);
    }
  };

  const occurrenceLimitOptions = VALUE_OPTIONS[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT].filter((option) =>
    akHash && isRealEstateClass(akHash) ? option !== 1000000 : option
  );

  useEffect(() => {
    if (setShowPLV2) setShowPLV2(showPlv2);
  }, [showPlv2, setShowPLV2]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormSection available={true}>
          <LabelWithToolTipCont id="PLClaims">
            <LabelLarge display="inline">
              For professional liability claims, I want my business to be covered up to the amount of:
            </LabelLarge>
            <ToolTipIcon
              content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]]}
              toolTipName="Professional_Liability_Limit"
              labelElementId="PLClaims"
            />
          </LabelWithToolTipCont>
          <MultiRow
            money={true}
            options={occurrenceLimitOptions}
            value={occurrenceLimit}
            updateValue={(value) => {
              adjustDeductibleOptions(value);
              updateSection('occurrenceLimit', value);
            }}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]}
          />
        </FormSection>
        {isSummaryPage && <SectionDivider height={20} />}
        <FormSection available={true}>
          <LabelWithToolTipCont id="PLDeductible">
            <LabelLarge display="inline">For professional liability claims, I want to pay a deductible of</LabelLarge>
            <ToolTipIcon
              content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.DEDUCTIBLE]]}
              toolTipName="Deductible"
              labelElementId="PLDeductible"
            />
          </LabelWithToolTipCont>
          <MultiRow
            money={true}
            options={VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE]}
            value={deductibleAmount}
            updateValue={(value) => updateSection('deductibleAmount', value)}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.DEDUCTIBLE]}
            disabledOptions={disabledDeductibleOptions}
          />
        </FormSection>
        {isSummaryPage && <SectionDivider height={20} />}
        {!showPlv2 && (
          <FormSection available={true}>
            <LabelLarge display="inline">
              How long would you like to be covered for work that your business performed in the past?
            </LabelLarge>
            <MultiRow
              options={VALUE_OPTIONS[POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]}
              value={yearsOfPriorActs}
              updateValue={(value) => updateSection('yearsOfPriorActs', value)}
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]}
            />
            {!isPriorActsValid && typeof yearsOfPriorActs !== 'undefined' && (
              <ErrorContainer>
                <ErrorIcon />
                <ErrorText>Prior Acts coverage period cannot exceed the age of the business.</ErrorText>
              </ErrorContainer>
            )}
          </FormSection>
        )}
        <RetroactiveDate />
        <SectionDivider height={20} />
        {!isSummaryPage && (
          <>
            <StyledSectionTitle>Professional Experience</StyledSectionTitle>
            <StyledParagraph>
              These questions are designed to gauge your qualification and experience in your industry.
            </StyledParagraph>
          </>
        )}
        <YesNoQuestion
          question="Does your industry require professional certifications?"
          value={certificationsRequired}
          selectYes={() => updateSection('certificationsRequired', true)}
          selectNo={() => updateSection('certificationsRequired', false)}
          cyLabel="certificationsRequired"
        />
        {isSummaryPage && <SectionDivider height={20} />}
        <YesNoQuestion
          question="Do you maintain professional certifications?"
          value={certificationsMaintained}
          selectYes={() => updateSection('certificationsMaintained', true)}
          selectNo={() => updateSection('certificationsMaintained', false)}
          cyLabel="certificationsMaintained"
        />
        {isSummaryPage && <SectionDivider height={20} />}
        <FormSection available={true}>
          <LabelLarge display="inline">How many years of professional experience do you have?</LabelLarge>
          <NumberInput
            value={yearsOfProfessionalExperience}
            onValueChange={adjustYearsExp}
            customWidth="quarter"
            data-cy={'years-of-professional-experience'}
            aria-label="Years of professional experience"
          />
        </FormSection>
        {isSummaryPage ? (
          <>
            <SectionDivider height={20} />
            <SubmitButton active={isPageComplete} arrowLinks={false}>
              Add Policy
            </SubmitButton>
          </>
        ) : (
          <GhostButton />
        )}
      </form>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const page = getCurrentPage(state);
  const isSummaryPage = page === PAGES.SUMMARY;

  return {
    deductibleAmount: selectPlDeductible(state),
    occurrenceLimit: selectPlLimit(state),
    yearsOfPriorActs: selectYearsOfPriorActs(state),
    certificationsRequired: selectCertificationsRequired(state),
    certificationsMaintained: selectCertificationsMaintained(state),
    yearsOfProfessionalExperience: selectYearsOfProfessionalExperience(state),
    grossAnnualSales: selectGrossAnnualSales(state),
    isSummaryPage,
    isPageComplete: isSummaryPage ? isPLComplete(state, { ignoreDefaults: true }) : isPageComplete(state),
    isPriorActsValid: selectIsYearsOfPriorActsValid(state),
    akHash: selectAkHash(state),
  };
};

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 8px;
  padding-top: 20px;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 32px;
`;

const ErrorIcon = styled(AlertIcon)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: block;

  g {
    fill: ${(props) => props.theme.colors.alert.zero};
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 0 16px;
  width: 100%;
`;

const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.alert.zero};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.09px;
  margin: 0;
`;

export const RadioContainer = styled.fieldset`
  border: none;
  margin-top: 4px;
  margin-left: 0;
  padding: 0;
  li:last-child {
    margin-bottom: 0;
  }
`;

export default connect(mapStateToProps, {
  updateProfessionalLiability,
  handleFormSubmit,
  setShowPLV2,
})(ProfessionalLiability);
