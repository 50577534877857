import { parseISO, subYears, isBefore } from 'date-fns';
import isRetroactiveDateWithinBoundary from './isRetroactiveDateWithinBoundary';

type IsRetroactiveDateValidParams = {
  retroactiveDate?: string;
  businessStartDate: string;
  businessAgeInMonths: number;
  isRetroActiveDateChecked: boolean;
  policyStartDate?: Date;
};

const isRetroactiveDateValid = ({
  businessAgeInMonths,
  businessStartDate,
  retroactiveDate,
  isRetroActiveDateChecked,
  policyStartDate,
}: IsRetroactiveDateValidParams) => {
  // Business is less than a year old
  if (businessAgeInMonths < 12) {
    return { isValid: true, message: '' };
  }

  // Retroactive date is required but not provided
  if (!retroactiveDate && isRetroActiveDateChecked) {
    return {
      message: 'Requested Retroactive Date is a required field.',
      isValid: false,
    };
  }

  if (retroactiveDate) {
    const parsedRetroactiveDate = parseISO(retroactiveDate);

    // Retroactive date is earlier than the business start date
    if (businessStartDate && isBefore(parsedRetroactiveDate, parseISO(businessStartDate))) {
      return {
        message: 'Requested Retroactive Date cannot be earlier than the business start date.',
        isValid: false,
      };
    }

    // Retroactive date is more than 6 years in the past
    const maxRetroactiveDate = subYears(policyStartDate || new Date(), 6);
    if (isBefore(parsedRetroactiveDate, maxRetroactiveDate)) {
      return {
        message: 'Requested Retroactive Date cannot be more than 6 years in the past.',
        isValid: false,
      };
    }

    // Retroactive date is more than 6 years in the past from the policy start date
    if (!isRetroactiveDateWithinBoundary(retroactiveDate, policyStartDate as Date).isValid) {
      return {
        message: isRetroactiveDateWithinBoundary(retroactiveDate, policyStartDate as Date).message,
        isValid: false,
      };
    }
  }

  // Valid retroactive date
  return { isValid: true, message: '' };
};

export default isRetroactiveDateValid;
