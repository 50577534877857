import { useEffect } from 'react';
import { LabelLarge, FormSection, LabelWithToolTipCont } from '../../elements';
import styled from 'styled-components';
import ToolTipIcon from './ToolTipIcon';
import { setIsRetroactiveDateChecked, setRetroactiveDate } from '../../actions/application';
import {
  selectBusinessAgeInMonths,
  selectIsRetroactiveDateValid,
  selectIsRetroactiveDateChecked,
  selectRetroactiveDate,
  selectBusinessStartDate,
  selectPolicyStartDate,
} from '../../selectors/application';
import { getCurrentPage } from '../../selectors/routing';
import { POLICY_OPTIONS, TOOL_TIP_CONTENT_KEY, PAGES } from '../../constants';
import { isPageComplete } from '../../selectors/pageComplete';
import { connect } from 'react-redux';
import { isPLComplete } from '../../selectors/progress';
import toolTipContent from '../../helpers/toolTipContent';
import { ReactComponent as AlertIcon } from '../../icons/alert.svg';
import { addDays, parseISO } from 'date-fns';
import DatePicker from '../../elements/DatePicker';
import RadioBtn from '../../elements/RadioBtn';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';
import isRetroactiveDateWithinBoundary from '../../helpers/isRetroactiveDateWithinBoundary';

interface ProfessionalLiabilityProps {
  businessAgeInMonths?: number;
  businessStartDate?: string;
  isSummaryPage?: boolean;
  setIsRetroactiveDateChecked: (checked: boolean) => void;
  isPageComplete: boolean;
  isRetroActiveDateChecked: boolean;
  isRetroactiveDateValid: { isValid: boolean; message?: string };
  setRetroactiveDate: (value?: string) => void;
  retroactiveDate?: string;
  policyStartDate?: Date;
  updateValue?: (date: string) => void;
}

const RetroactiveDate = ({
  businessAgeInMonths,
  businessStartDate,
  setIsRetroactiveDateChecked,
  isRetroActiveDateChecked,
  isRetroactiveDateValid,
  isSummaryPage,
  policyStartDate,
  updateValue,
  retroactiveDate,
  setRetroactiveDate,
}: ProfessionalLiabilityProps) => {
  const { showPlv2 } = useFeatureFlags();

  const handleIsRetroactiveDateCheckedChange = (checked: boolean) => {
    setIsRetroactiveDateChecked(checked);
    // Clear retroactive date if unchecked
    if (!checked) {
      setRetroactiveDate(undefined);
    }
    if (isSummaryPage && updateValue) {
      updateValue(retroactiveDate as string);
    }
  };

  useEffect(() => {
    if (!showPlv2 || (businessAgeInMonths && businessAgeInMonths < 11)) {
      setRetroactiveDate(undefined);
      setIsRetroactiveDateChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPlv2, businessAgeInMonths]); // setRetroactiveDate and setIsRetroactiveDateChecked are not included in the dependency array to prevent infinite loop

  useEffect(() => {
    if (isSummaryPage && updateValue) {
      updateValue(retroactiveDate as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retroactiveDate, isSummaryPage]); // updateValue is not included in the dependency array to prevent infinite loop

  if (!showPlv2 || (businessAgeInMonths && businessAgeInMonths < 12)) return null;

  return (
    <StyledFormSection isSummaryPage={isSummaryPage} available={true} style={{ minHeight: 'auto' }}>
      <StyledLabelWithToolTipCont isSummaryPage={isSummaryPage} id="retroactiveDate">
        <RetroactiveQuestionLabel isSummaryPage={isSummaryPage} display="inline" htmlFor="retroactive-date-preference">
          Would you like a Retroactive Date that is different from the policy start date?
        </RetroactiveQuestionLabel>
        {!isSummaryPage && (
          <ToolTipIcon
            content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.RETROACTIVE_DATE]]}
            toolTipName="Requested_Retroactive_Date"
            labelElementId="retroactiveDate"
          />
        )}
      </StyledLabelWithToolTipCont>
      <RadioContainer>
        <RadioBtn
          name="has-retroactive-date"
          id="yes-retroactive"
          dataCy="yes-retroactive"
          labelText="Yes"
          isChecked={isRetroActiveDateChecked}
          onChange={() => handleIsRetroactiveDateCheckedChange(true)}
        />
        <RadioBtn
          name="has-retroactive-date"
          id="no-retroactive"
          dataCy="no-retroactive"
          labelText="No"
          isChecked={!isRetroActiveDateChecked}
          onChange={() => handleIsRetroactiveDateCheckedChange(false)}
        />
      </RadioContainer>
      {isRetroActiveDateChecked && (
        <RetroactiveDatePickerContainer isSummaryPage={isSummaryPage}>
          <RetroactiveLabel display="inline" isSummaryPage={isSummaryPage}>
            Requested Retroactive Date
          </RetroactiveLabel>
          <ReactDatePicker>
            <DatePicker
              fullWidth
              date={retroactiveDate ? parseISO(retroactiveDate) : null}
              disabled={false}
              // @ts-ignore
              setDate={(date) => setRetroactiveDate(date?.toISOString())}
              minDate={parseISO(businessStartDate as string)}
              maxDate={addDays(new Date(), 90)}
              placeholder="MM/DD/YYYY"
            />
          </ReactDatePicker>
          {!isRetroactiveDateValid.isValid && isRetroActiveDateChecked && (
            <ErrorContainer>
              <ErrorIcon />
              <ErrorText>{isRetroactiveDateValid?.message}</ErrorText>
            </ErrorContainer>
          )}
          {isSummaryPage &&
            retroactiveDate &&
            policyStartDate &&
            !isRetroactiveDateWithinBoundary(retroactiveDate, policyStartDate).isValid && (
              <ErrorContainer>
                <ErrorIcon />
                <ErrorText>{isRetroactiveDateWithinBoundary(retroactiveDate, policyStartDate).message}</ErrorText>
              </ErrorContainer>
            )}
        </RetroactiveDatePickerContainer>
      )}
    </StyledFormSection>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const page = getCurrentPage(state);
  const isSummaryPage = page === PAGES.SUMMARY;

  return {
    businessStartDate: selectBusinessStartDate(state),
    businessAgeInMonths: selectBusinessAgeInMonths(state),
    policyStartDate: selectPolicyStartDate(state),
    isSummaryPage,
    isPageComplete: isSummaryPage ? isPLComplete(state, { ignoreDefaults: true }) : isPageComplete(state),
    isRetroActiveDateChecked: selectIsRetroactiveDateChecked(state),
    isRetroactiveDateValid: selectIsRetroactiveDateValid(state),
    retroactiveDate: selectRetroactiveDate(state),
  };
};

const ErrorIcon = styled(AlertIcon)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: block;

  g {
    fill: ${(props) => props.theme.colors.alert.zero};
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 0 16px;
  width: 100%;
`;

const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.alert.zero};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.09px;
  margin: 0;
`;

const StyledFormSection = styled(FormSection)<{ isSummaryPage?: boolean }>`
  padding: ${(props) => props.isSummaryPage && '0 !important'};
  width: ${(props) => props.isSummaryPage && ''};
  margin-bottom: ${(props) => props.isSummaryPage && '39px'};
`;

const StyledLabelWithToolTipCont = styled(LabelWithToolTipCont)<{ isSummaryPage?: boolean }>`
  padding: ${(props) => props.isSummaryPage && '0 !important'};
`;

const RetroactiveDatePickerContainer = styled.div<{ isSummaryPage?: boolean }>`
  margin: ${(props) => (props.isSummaryPage ? '16px 0' : '32px 0')};
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker__input-container input {
    max-width: 319px;
  }
`;

const ReactDatePicker = styled.div`
  .date-picker-container {
    margin-bottom: 8px;
  }
`;

export const RadioContainer = styled.fieldset`
  border: none;
  margin-top: 4px;
  margin-left: 0;
  padding: 0;
  li:last-child {
    margin-bottom: 0;
  }
`;

const RetroactiveQuestionLabel = styled(LabelLarge)<{ isSummaryPage?: boolean }>`
  font-weight: ${(props) => (props.isSummaryPage ? '400' : '600')};
`;

const RetroactiveLabel = styled(LabelLarge)<{ isSummaryPage?: boolean }>`
  font-size: ${(props) => (props.isSummaryPage ? '12px' : 'inherit')};
`;

export default connect(mapStateToProps, {
  setIsRetroactiveDateChecked,
  setRetroactiveDate,
})(RetroactiveDate);
