import React, { Fragment } from 'react';
import styled from 'styled-components';
import { formatCurrency } from './formatNumber';

interface IDictionary {
  [id: string]: JSX.Element | ((...args: any[]) => JSX.Element);
}

export const Text = styled.p<{ weight?: string }>`
  color: ${(props) => props.theme.icons.toolTip.content.fontColor};
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 13px;
  margin: 10px 10px;
  line-height: 1.36;
  font-weight: ${(props) => (props.weight === 'bold' ? 'bold' : 'normal')};
`;
const Bullet = styled.li`
  color: ${(props) => props.theme.charcoalGrey};
  font-family: ${(props) => props.theme.font.typeface.secondary};
  line-height: 1.36;
  font-size: 12px;
`;

const toolTipContent: IDictionary = {
  GL: (
    <Fragment>
      <Text>
        Your General Liability limit is the max that your insurance company will pay for damages due to slips, trips,
        falls and damage to someone else’s property.
      </Text>
      <Text>
        If you have a loss that is $750,000 and your General Liability limit is set at only $500,000, you are
        responsible for paying the additional $250,000.
      </Text>
      <Text weight="bold">So make sure you think about what limits you need!</Text>
    </Fragment>
  ),
  PL_LIMITS: (
    <Fragment>
      <Text>
        Your Professional Liability limit is the max that your insurance company will pay for damaging someone else
        through the advice you give, failing to deliver on time or even failing to meet expectations.
      </Text>
      <Text>
        This coverage is specifically tailored to what type of work you do. If someone sues you because you gave them a
        bad haircut and they didn’t get the job they interviewed for, your professional liability will kick in to
        protect you.
      </Text>
      <Text weight="bold">
        But don’t forget you are responsible for paying for anything that exceeds your limit. So, make sure you think
        about what limits you need to properly cover your business!
      </Text>
    </Fragment>
  ),
  PD_DEDUCTIBLE: (
    <Fragment>
      <Text>
        Property Damage Liability Covers damage done to someone elses' property. This deductible is the amount you are
        responsible for any claims involving Property Damage Liability
      </Text>
    </Fragment>
  ),
  PL_DEDUCTIBLE: (
    <Fragment>
      <Text>
        Your Professional Liability Deductible is the amount that you are responsible to pay when you someone sues you
        due to an error or mistake that you made while performing your work.
      </Text>
      <Text>
        If you have a Professional Liability Deductible of $1,000 and someone sues you for $50,000 you are responsible
        for $1,000 your insurance company will pay the remaining $49,000.
      </Text>
    </Fragment>
  ),
  BIZ_PROP_DEDUCTIBLE: (
    <Fragment>
      <Text>
        Your Property Deductible is the amount that you are responsible to pay when you file a claim for damages to your
        stuff, such as computers, desks, and anything else used in the operation of your business.
      </Text>
    </Fragment>
  ),
  WIND_HAIL_DEDUCTIBLE: (
    <Fragment>
      <Text>
        The deductible shown applies separately to building and business personal property for losses caused by wind,
        hurricane, and hail damage. You pay a portion of the loss for each coverage subject to a minimum amount of
        $2,500.
        <Text></Text>
        This deductible amount is not editable.
      </Text>
    </Fragment>
  ),
  BPP_LIMIT: (
    <Text>
      This limit provides coverage to repair or replace your business equipment and property such as tools, computers,
      desks, chairs and other items used in the operation of your business.
    </Text>
  ),
  BUILDING_LIMIT: (
    <Text>This limit provides coverage to repair or rebuild your building if it is damaged or destroyed.</Text>
  ),
  ANNUAL_PAYROLL: <Text>Roughly, what do you expect to pay yourself and your employees over the next 12 months?</Text>,
  GROSS_ANNUAL_SALES: (
    <Fragment>
      <Text>Select the amount that most closely represents your business's sales, rounding up.</Text>
    </Fragment>
  ),
  NUMBER_EMPLOYEES: (
    <Fragment>
      <Text>This is the number of employees, including yourself, who you employ (part-time and full-time).</Text>
      <Text>This does not include independent contractors which work for your business on a 1099/contract basis.</Text>
    </Fragment>
  ),
  LEGAL_BUSINESS_NAME: (
    <Text>
      The legal entity is who the policy covers for its operations and property. Examples include a corporation, LLC,
      LLP, sole proprietorship, etc.
      <br />
      <br />
      This will print as the Named Insured on the policy.
    </Text>
  ),
  DBA: (
    <Text>
      Use the “Doing Business As” field for an operating or trade name. Entering a DBA name can assist in our data
      gathering for this business to build your quote.
    </Text>
  ),
  CONSTRUCTION: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Contractors</Bullet>
        <Bullet>Renovations</Bullet>
        <Bullet>New construction</Bullet>
        <Bullet>Electrical</Bullet>
        <Bullet>Plumbing</Bullet>
        <Bullet>Roofing</Bullet>
      </ul>
    </Fragment>
  ),
  REPAIR: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Electronics repair</Bullet>
        <Bullet>Mechanics</Bullet>
        <Bullet>Lawn maintenance</Bullet>
      </ul>
    </Fragment>
  ),
  REAL_ESTATE: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Realtor</Bullet>
        <Bullet>Title agent</Bullet>
      </ul>
    </Fragment>
  ),
  PERSONAL_SERVICES: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Life coach</Bullet>
        <Bullet>Personal organizer</Bullet>
        <Bullet>Hair/nail salon</Bullet>
        <Bullet>Dry cleaning</Bullet>
        <Bullet>Packaging services</Bullet>
        <Bullet>Cleaning services</Bullet>
      </ul>
    </Fragment>
  ),
  TECH: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Software engineer</Bullet>
        <Bullet>Project Management</Bullet>
        <Bullet>Systems Administrator</Bullet>
      </ul>
    </Fragment>
  ),
  FINANCIAL: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Accountants</Bullet>
        <Bullet>Insurance agents</Bullet>
        <Bullet>Payroll services</Bullet>
      </ul>
    </Fragment>
  ),
  MEDIA: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Writer</Bullet>
        <Bullet>Photographer</Bullet>
        <Bullet>Graphic designer</Bullet>
        <Bullet>Interior Design</Bullet>
      </ul>
    </Fragment>
  ),
  RETAIL: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Storefront</Bullet>
        <Bullet>Distributor</Bullet>
        <Bullet>Online Shop</Bullet>
      </ul>
    </Fragment>
  ),
  PROFESSIONAL_SERVICES: (
    <Fragment>
      <Text weight="bold">Examples:</Text>
      <ul>
        <Bullet>Legal</Bullet>
        <Bullet>Employment agency</Bullet>
        <Bullet>Marketing services</Bullet>
      </ul>
    </Fragment>
  ),
  LIQUOR_LIABILITY: (
    <Text>
      Liquor Liability covers businesses that manufacture, sell, and/or serve alcohol. This coverage provides protection
      for lawsuits or settlements associated with the bodily injury or property damage of others.
    </Text>
  ),
  COMPUTER_FRAUD: (
    <Text>
      Computer Fraud coverage protects against the direct loss of money, securities and other property that is
      fraudulently transferred from the use of a computer system.
    </Text>
  ),
  EPLI: (
    <Text>
      EPLI provides coverage against claims made by employees for discrimination, wrongful termination, harassment, and
      other employment-related issues.
    </Text>
  ),
  IDENTITY_FRAUD: <Text>Identity Fraud coverage provides reimbursement compensation related to identity theft.</Text>,
  DISCRETIONARY_PAYROLL: (
    <Text>Discretionary Payroll Coverage provides money to pay for payroll for select employees for 120 days.</Text>
  ),
  ELECTRONIC_DATA_LIABILITY: (
    <Text>
      Electronic Data Liability provides coverage for the loss of, damage to, or inability to access or use
      electronically stored data.
    </Text>
  ),
  FINE_ARTS: (
    <Text>
      Fine Arts coverage provides protection for paintings, statues, rugs, tapestries, and other artwork used to
      decorate your business.
    </Text>
  ),
  SNOW_PLOW: (
    <Text>
      This coverage expands general liability coverage to include businesses that perform snow plow operations. Your
      business will be covered for bodily injury or property damage to others as a result of snow plow operations.
    </Text>
  ),
  ORDINANCE_LAW: (
    <Text>
      Ordinance or Law Coverage provides coverage to your business for extra rebuilding expenses incurred due to
      building codes.
    </Text>
  ),
  DEBRIS_REMOVAL: (
    <Text>Debris Removal coverage provides extra coverage for clean-up costs if your property is damaged.</Text>
  ),
  DISASTER_INCREASE: (
    <Text>Disaster increase coverage pays for extra rebuilding costs as a result of a natural disaster.</Text>
  ),
  EMPLOYEE_BENEFITS_LIABILITY: (
    <Text>
      Employee benefits liability pays for errors or omissions committed by an employer when administering the employee
      benefits program.
    </Text>
  ),
  GREEN_UPGRADES: (
    <Text>
      Green Upgrades Coverage provides additional coverage for replacing or rebuilding with more "energy efficient"
      materials.
    </Text>
  ),
  WATER_BACKUP: (
    <Text>
      Water Backup coverage provides protection against losses resulting from a backup of drains, sewers, or sump pump
      failure.
    </Text>
  ),
  ACCOUNTS_RECEIVABLE: (
    <Text>
      This coverage insures against the loss of sums owed by the business customers that are unable to be collected
      because of loss of data or damage to accounts receivable records. This limit is split for records that are kept on
      premises and records that are kept off premises.
    </Text>
  ),
  PROPERTY_IN_STORAGE: (
    <Text>
      This coverage applies to your business equipment that is temporarily stored in portable storage units. The
      coverage insures property that has been stored for less than 90 days (not more than), and the storage unit itself
      cannot have been installed and in use at the described premises for more than 90 days.
    </Text>
  ),
  FD_SERVICE_CHARGE: (
    <Text>
      This coverage applies to charges incurred from requiring fire department services to save or protect Covered
      Property from a Covered Cause of Loss at each premises.
    </Text>
  ),
  CONSTRUCTION_COST: (
    <Text>
      This coverage applies to enforcement of ordinances and laws regulating construction or repair of damaged
      buildings. Older buildings can sometimes require updating during reconstruction or repair after a covered loss.
      Increased costs incurred from ordinances or laws are covered up to the described limit.
    </Text>
  ),
  OUTDOOR_PROPERTY: (
    <Text>
      This coverage applies to outdoor fences, antennas, signs, trees, shrubs, and plants that are damaged by fire,
      lightning, explosion, riot or civil commotion, or aircraft. Coverage is limited to $2,500 total but no more than
      $1,000 for any one shrub or plant.
    </Text>
  ),
  PRESERVATION_OF_PROPERTY: (
    <Text>
      This limit applies to Covered Property being moved from the insured location and being temporarily stored at
      another location as a result of a covered loss in order to preserve it. Coverage only applies if loss or damage
      occurs within 30 days after the property is first moved.
    </Text>
  ),
  VALUABLE_PAPERS: (
    <Text>
      This coverage applies to Valuable Papers and Records that are stored at the insured premises and the cost to
      research, replace, or restore lost information after direct physical loss or damage from a covered cause of loss.
      For Valuable Papers and Records stored off premises a separate limit of $5,000 applies.
    </Text>
  ),
  BUSINESS_INCOME_EXTRA_EXPENSE: (
    <Text>
      This coverage applies to loss of income due to the necessary suspension of operations as a result of direct
      physical damage to property at the described premises that is caused by a Covered Cause of Loss. Ordinary payroll
      expenses are covered for only 60 days from the date of loss. Extra Expense are those expenses which help avoid or
      minimize the suspension of business and to continue operations.
    </Text>
  ),
  BUSINESS_INCOME_DEPENDENT_PROPERTIES: (
    <Text>
      This coverage applies to secondary dependent properties that sustain physical loss due to a Covered Cause of Loss.
      Business income from the dependent property is covered up to the described limit.
    </Text>
  ),
  CIVIL_AUTHORITY: (
    <Text>
      This coverage applies to a loss due to the action of a civil authority that prohibits access to the described
      premises, including reimbursement for loss of Business Income and Extra Expense.
    </Text>
  ),
  ELECTRONIC_DATA: (
    <Text>
      This coverage pays for the cost to replace or restore “electronic data” which has been destroyed or corrupted by a
      Covered Cause of Loss such as computer virus, harmful code, or similar instruction entered into your computer
      network.
    </Text>
  ),
  INTERRUPTION_OF_COMPUTER_OPERATIONS: (
    <Text>
      This coverage pays for Business Income and Extra Expenses incurred as a result of a computer virus, harmful code,
      or similar instruction entered into your computer network.
    </Text>
  ),
  FIRE_EXTINGUISHER_RECHARGE: (
    <Text>
      This coverage pays for the replacing or recharging of your fire extinguishers and fire extinguishing systems if
      they are discharged on or within 100 feet of the described premises.
    </Text>
  ),
  FORGERY: (
    <Text>
      This coverage pays for a loss resulting from the forgery or alteration of any check, draft, promissory note, bill
      of exchange, or similar written promise of money.
    </Text>
  ),
  MONEY_AND_SECURITIES: (
    <Text>
      This coverage pays for the loss of money and securities used in your business as a result of theft, disappearance,
      or destruction. This coverage does not pay for mathematical or accounting errors.
    </Text>
  ),
  MONEY_ORDERS_COUNTERFEIT: (
    <Text>
      This coverage pays for a loss due to counterfeit currency or the acceptance in good faith of a money order that is
      not paid upon presentation in exchange for merchandise, money or services.
    </Text>
  ),
  NEW_PROPERTY: (
    <Text>
      This coverage protects against disasters that happen to buildings under construction, or newly acquired property
      that hasn't been added to the insured’s policy yet. Coverage is provided for 30 days from the date they are
      acquired, or construction has begun.
    </Text>
  ),
  PERSONAL_EFFECTS: (
    <Text>
      This coverage pays for loss to personal effects owned by the insured, their officers, their partners or members,
      their managers, and their employees, including temporary and leased employees. This coverage does not pay for
      tools or equipment used in the business or for loss due to theft.
    </Text>
  ),
  PERSONAL_PROPERTY_OFF_PREMISES: (
    <Text>
      This coverage pays for loss to covered property off premises while it is in transit, or at a premise that is not
      owned, leased, or operated by the insured. Money, securities, valuable paper and records and accounts receivable
      are not covered under this coverage.
    </Text>
  ),
  GL_LIMIT: (
    <Text>
      This is the limit of coverage that Coterie will pay for each individual claim of General Liability of Products
      Completed coverage.
    </Text>
  ),
  GL_DEDUCTIBLE: (
    <Text>
      This deductible is the amount that you are responsible for any claims involving Property Damage Liability.
      Property Damage Liability covers damage to someone elses’ property.
    </Text>
  ),
  MEDICAL_EXPENSE_LIMIT: (
    <Text>
      This coverage applies to injuries incurred by a third party sustained in an accident as a result of your business
      activities, regardless of fault. For example, a client tripping on your work equipment and injuring himself.
    </Text>
  ),
  DAMAGE_TO_RENTED_PREMISES: (
    <Text>
      This coverage applies to fire damage to buildings that are rented, temporarily occupied, or are in the car,
      custody or control of the insured for 7 days or less.
    </Text>
  ),
  PRODUCTS_COMPLETED_OPERATIONS: (
    <Text>
      This is the limit of coverage that Coterie will pay for all the insured's claims of General Liability related to
      Products or Completed Operations within a year.
    </Text>
  ),
  ENDORSEMENTS: (
    <Text>
      Endorsements are add-ons to your policy that provide extra coverage. Depending on your needs, you may want to
      consider one of the below endorsements:
    </Text>
  ),
  DISCOUNT_APPLIED: <Text>The discount is applied automatically to your policy quote</Text>,
  FEES_APPLIED: (fee: number, paymentInterval: string, savings: number) => (
    <Text>
      {formatCurrency(fee)} in fees will be added to your total today.{' '}
      {paymentInterval === 'Monthly' &&
        `Each time you make a monthly payment, an additional
      installment fee will apply. Save $${savings} by paying in full.`}
    </Text>
  ),
  RETROACTIVE_DATE: (
    <Text>
      The retroactive date is the beginning of the coverage period. Our policy will cover events that happened after the
      retroactive date which are unknown at the time of policy purchase and reported during the policy period.
    </Text>
  ),
};

export default toolTipContent;
