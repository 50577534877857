import { fetchExistingApplication, setApiToken, setApiTokenForVersion } from '../api';
import {
  setPartnerIntroViewed,
  setPartnerFromApplication,
  setAvailablePolicyTypes,
  setApplicationFromServer,
  fatalError,
  setExistingApplicationLoaded,
  showNextPage,
  didViewAdditionalInsureds,
  didViewGLPage,
  clearApplication,
  didViewAddOnsPage,
  setUserHasExistingApplication,
} from './application';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { applicationError } from './quote';
import handleGetIndustryName from './handleGetIndustryName';
import { glValuesComplete } from '../selectors/routing';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { api_versions, POLICY_DEFAULTS } from '../constants';
import { setAgent } from './agent';
import { setReferralPartner } from './referralPartner';
import { setLossControlValueHelper } from '../helpers/lossControl';

const loadExistingApplication = (
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>,
  getState: () => ReduxState,
  incomingApplicationId: string
) => {
  return fetchExistingApplication(incomingApplicationId)
    .then((res) => {
      const { agent, application, availablePolicyTypes, referralPartner, partner } = res.data;
      const hasBop = application?.applicationTypes?.includes('BOP');
      const hasGL = application?.applicationTypes?.includes('GL');
      const lossControlOverride =
        hasBop || hasGL
          ? { lossControl: setLossControlValueHelper(application.lossControl, POLICY_DEFAULTS.GL_BOP.lossControl) }
          : {};
      const applicationWithDefaults = {
        ...application,
        ...lossControlOverride,
      };

      dispatch(clearApplication());
      dispatch(setPartnerIntroViewed(false));
      dispatch(didViewAdditionalInsureds());
      dispatch(didViewAddOnsPage());
      dispatch(setUserHasExistingApplication(true));

      if (!res.data || !application) {
        reportToSentry(`Couldn't load existing application: ${incomingApplicationId}`, {
          name: 'api response',
          data: res,
        });
        dispatch(applicationError(["Couldn't load application"]));
      } else {
        dispatch(setExistingApplicationLoaded(incomingApplicationId));

        if (glValuesComplete(application)) {
          dispatch(didViewGLPage());
        }

        if (application?.industryId) {
          dispatch(handleGetIndustryName(application.industryId) as any);
        }

        if (agent) {
          dispatch(setAgent(agent));
        }

        if (partner) {
          dispatch(setPartnerFromApplication(partner));
          if (agent?.producerExternalID) {
            setApiTokenForVersion(api_versions.V1, partner.id);
          } else {
            setApiToken(partner.id);
          }
        }
        if (referralPartner?.id) {
          setApiTokenForVersion(api_versions.V1_6, referralPartner.id);
          dispatch(setReferralPartner(referralPartner));
        }

        dispatch(setAvailablePolicyTypes(availablePolicyTypes));
        dispatch(setApplicationFromServer(applicationWithDefaults));
        dispatch(showNextPage());
      }
    })
    .catch((error) => {
      reportToSentry(error, getRequestContext({ incomingApplicationId }));
      dispatch(fatalError(error));
    });
};

export default loadExistingApplication;
