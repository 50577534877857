import { subYears, isBefore, parseISO } from 'date-fns';

// Retroactive date is more than 6 years in the past from the policy start date
const isRetroactiveDateWithinBoundary = (retroactiveDate: string, policyStartDate: Date) => {
  const boundaryDate = subYears(policyStartDate as Date, 6);
  if (isBefore(parseISO(retroactiveDate as string), new Date(boundaryDate))) {
    return {
      message: 'Please enter a date within 6 years of the policy start date.',
      isValid: false,
    };
  }
  return { isValid: true };
};

export default isRetroactiveDateWithinBoundary;
