import React from 'react';
import { ChildrenContainer, CancelButton } from '../styles';
import { PrimaryButton } from '../..';

interface DatePickerChildrenProps {
  setDate: () => void;
  disabled: boolean;
  cancelPicker: () => void;
}

const DatePickerChildren1 = ({ setDate, disabled, cancelPicker }: DatePickerChildrenProps) => (
  <ChildrenContainer>
    <PrimaryButton disabled={disabled} onClick={setDate} data-cy="datePickerSelectDateBtn">
      Select Date
    </PrimaryButton>
    <CancelButton onClick={cancelPicker} data-cy="datePickerCancelBtn">
      Cancel
    </CancelButton>
  </ChildrenContainer>
);

export default DatePickerChildren1;
